import React, { Component } from "react";

import Logo from "../assets/img/logo-dark.svg";
import AdminMenuList from "./Menu";

export default class Sidebar extends Component {
  render() {
    return (
      <div
        id="side-menu"
        className="bg-secondary side-menu"
      >
        <div className="p-4">
          <a href="/" className="router-link-active">
            <img src={Logo} height="35px" alt="" className="mt-2" />
          </a>
        </div>
        <hr className="mt-3" />
        <div className="mt-4">
          <AdminMenuList/>
        </div>
      </div>
    );
  }
}
