import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import Avatar from "../assets/img/default-user-avatar.png";


import swal from 'sweetalert';
import $ from "jquery";

import "../assets/css/app.css";

import Menu1 from "../assets/img/menu-light-e.svg";
import Logo from "../assets/img/logo-light.svg";
import MenuList from "./Menu";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"]
              ? JSON.parse(localStorage["appState"]).user.token
              : "",
            user: [],
    };
  }
  componentDidMount() {
    axios
            .get(`${axios.defaults.baseURL}/user/${JSON.parse(localStorage["appState"]).user.id}`,
            {
              headers: {
                'Authorization': `Bearer ${this.state.token}`
              }
            }
            )
            .then(response => {
              //console.log(response);
              return response;
            })
            .then(json => {
              if (json.data.success) {
                this.setState({ user: json.data.user });
              // } else alert("Cannot Fetch Data!");
              } else swal("Oops!", "Cannot Fetch Data!", "error");;
            })
            .catch(error => {
              console.error(`An Error Occured! ${error}`);
            });
            // alert(id)
  }
  logoutUser = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  render() {

    // switch function for login/signup
    const mobileMenuOpen = e => {
        $("#mobile-navbar").addClass('open-mobile-menu');
        $(".mobile-menu-overlay").addClass('show-mobile-menu-overlay');
    };

    const mobileMenuClose = e => {
        $("#mobile-navbar").removeClass('open-mobile-menu');
        $(".mobile-menu-overlay").removeClass('show-mobile-menu-overlay');
    };
    
    return (
      <>
        <div id="main-app" className="position-relative">
          <div className="curtain position-absolute w-100 top-0 left-0 dashboard-bg"></div>
          <header>
            <div className="bigger-screen-only container">
              <div className="row py-2 py-md-2">
                <div className="text-left col-md-2 col-4">
                  <div className="d-block d-md-none">
                    <img src={Logo} height="25" alt="" />
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center col-md-10 col-8">
                  <div className="d-flex align-items-center float-right">
                    <div
                      className="dropdown b-dropdown profilemenu text-center btn-group"
                      id="__BVID__36"
                    >
                      <button
                        aria-haspopup="true"
                        aria-expanded="false"
                        type="button"
                        className="btn dropdown-toggle btn-link btn-lg text-decoration-none dropdown-toggle-no-caret"
                        id="__BVID__36__BV_toggle_"
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="bg-light dp-wrapper p-1 d-flex shadow-xl" style={{ borderRadius:"50%" }}>
                            <Link to="/my-account"><img
                              src={this.state.user.avatar || Avatar}
                              id="user-logo"
                              className="bg-dark"
                              style={{ width: "100%", height: "100%", borderRadius:"50%" }}
                            /></Link>
                          </div>

                          <Link onClick={this.logoutUser} className="btn-outline  nav-link">Logout</Link>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div
            id="navbar"
            className="sticky-top d-block d-md-none"
            style={{ zIndex: "100" }}
          >
            <div
              className="shadow-holder w-100 position-absolute shadow-lg"
              style={{ bottom: "0px", height: "50%" }}
            ></div>
            <nav className="navbar navbar-expand-md bg-light p-0">
              <div className="nav-wrapper w-100">
                <div className="container">
                  <div className="row py-2 py-md-2 align-items-center justify-content-between">
                    <div className="desktop-menus d-none d-md-block col"></div>
                    <div className="d-block d-md-none w-100 pl-3 col-1">
                      <button className="btn float-left pl-0">
                        <img
                          src={Menu1}
                          height="30"
                          alt=""
                          style={{ marginTop: "-10px" }}
                          onClick={mobileMenuOpen}
                        />
                      </button>
                    </div>
                    {/* <div className="ml-3 col-3" style={{marginTop:"-10px"}}>
                          <div className="d-block d-md-none">
                            <img src={Menu1} height="25" alt="" />
                          </div>
                        </div> */}
                    <div className="col">
                      <div className="d-flex float-right">
                        <div className="d-flex align-items-center justify-content-center">
                        <div className="bg-light dp-wrapper p-1 d-flex shadow-xl" style={{ borderRadius:"50%" }}>
                            <img
                              src={this.state.user.image || Avatar}
                              id="user-logo"
                              className="bg-dark"
                              style={{ width: "100%", height: "100%", borderRadius:"50%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="mobile-nav">
          <div id="mobile-navbar" className="position-fixed bg-secondary h-100 side-menu">
            <div
              className="user-meta w-100 bg-transparent"
              style={{ height: "180px" }}
            >
              <button className="btn" onClick={mobileMenuClose}>
                <span
                  aria-label="Close icon"
                  role="img"
                  className="material-design-icon close-icon"
                >
                  <svg
                    fill="#ffffff"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="material-design-icon__svg"
                  >
                    <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z">
                      <title>Close icon</title>
                    </path>
                  </svg>
                </span>
              </button>
              <div className="h-100 container">
                <div className="row h-100 justify-content-between ml-1 bg-transparent">
                  <div className="mt-5 col">
                    <a href="/profile" className="">
                      <h4>
                        <span className="text-primary">Hi,</span>
                        <span className="text-light ml-2">{this.state.user.lname || ""}</span>
                      </h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <MenuList/>
            </div>
            <Link onClick={this.logoutUser} className="btn-outline  nav-link">Logout</Link>
          </div>
          <div
            className="mobile-menu-overlay w-100 h-100 position-fixed"
            style={{ top: "0px", left: "0px", zIndex: "1035" }}
          ></div>
        </div>
      </>
    );
  }
}
