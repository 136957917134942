import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";

import { Link, useLocation } from "react-router-dom";

import NumberFormat from "react-number-format";

import axios from "axios";
import swal from "sweetalert";
import $ from "jquery";

const CaseDetails = () => {
  const { state } = useLocation();

  const [user, setUser] = useState([]);
  const [lawyer, setLawyer] = useState([]);
  const [token, setToken] = useState(
    JSON.parse(localStorage["appState"]).user.token
  );

  useEffect(() => {
    console.log("get id: ", state?.id);
    getUser();
  }, []);

  const getUser = () => {
    setToken(JSON.parse(localStorage["appState"]).user.token);
    console.log(token);
    var formData = new FormData();
    formData.append("source", state.source);
    formData.append("id", state.id);

    axios
      .post(`${axios.defaults.baseURL}/view-case`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          setUser(json.data.source);
          setLawyer(json.data.lawyer);
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
    // alert(id)
  };

  const closeCase = () => {
    var formData = new FormData();
    formData.append("source", state.source);
    formData.append("id", state.id);

    axios
      .post(`${axios.defaults.baseURL}/close-case`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log(response);
        swal("Success!", response.data.message, "success", {
          button: false,
          timer: 1500,
        });
        window.location.reload();
        return response;
      });
  };

  return (
    <div id="app" className="bg-gray-200">
      <div className="dashboard-page">
        <div className="private-layout">
          <div className="app-wrapper">
            <Sidebar />
            <Header />
            <div id="main-app" className="position-relative">
              <div className="container mt-4">
                <div className="page-title">
                  <div className="row text-primary py-2 pt-4">
                    <div className="col-10">
                      <div
                        className="user-welcome"
                        style={{ overflow: "hidden" }}
                      >
                        <Link
                          to={{
                            pathname: `/admin/${state.source}-list`,
                          }}
                        >
                          <i className="ti-arrow-left"></i> Back
                        </Link>
                        <div className="wrapper d-flex mt-2">
                          <h4 className="d-inline-block">
                            Case Details - {state.id}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="text-right col-2"></div>
                  </div>
                </div>

                <div>
                  <div data-v-22924209="" class="dash-card-plan bg-white mb-4">
                    <div className="row">
                      {user.avatar && (
                        <div className="col-md-4 text-center">
                          <img
                            src={user.avatar}
                            className="user-img p-2"
                            alt=""
                          />
                          <br />
                          <br />
                          {/* <Link onClick={rejectVolunteer.bind(this, user.id)} className="btn mr-2 btn-danger">
                              Reject
                          </Link> 
                          <Link onClick={closeCase()} className="btn mr-2 btn-success">
                              Close Case
                          </Link>*/}
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="p-4">
                          <div className="">
                            <h5 className="mb-0">User Details</h5>
                          </div>
                          <br />
                          <div className="row mb-4">
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>Firstname: {user.fname}</b>
                              </p>
                            </div>
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>Lastname: {user.lname}</b>
                              </p>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <p className="mb-1">
                                <b>Email: {user.email}</b>
                              </p>
                            </div>
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>Telephone: {user.phone}</b>
                              </p>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <p className="mb-1">
                                <b>Country: {user.country}</b>
                              </p>
                            </div>
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>State: {user.state}</b>
                              </p>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>City: {user.city}</b>
                              </p>
                            </div>
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>Address: {user.address}</b>
                              </p>
                            </div>
                          </div>
                          <br />
                          <div className="">
                            <h5 className="mb-4">Case Details</h5>
                          </div>

                          <div className="">
                            <p className="mb-1 titlecase">
                              <b>
                                Brief:
                                <br />
                                {user.brief}
                              </b>
                            </p>
                          </div>
                          <br />
                          <div className="row mb-4">
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>Location: {user.location}</b>
                              </p>
                            </div>
                            <div className="col">
                              <p className="mb-1 titlecase">
                                <b>Status: {user.status}</b>
                              </p>
                              <br />
                              <button
                                onClick={() => closeCase()}
                                className="btn btn-sm mr-2 btn-danger"
                              >
                                Close Case
                              </button>
                            </div>
                          </div>
                          <br />
                          <div className="">
                            <h5 className="mb-4">Case Report</h5>
                          </div>

                          <div className="">
                            <p className="mb-1 titlecase">
                              <b>
                                Assigned Lawyer: {lawyer.fname} {lawyer.lname}
                              </b>
                            </p>
                            <br />
                            {user.report && (
                              <p className="mb-1 titlecase">
                                <b>
                                  Report from Lawyer: <br />
                                  {user.report}
                                </b>
                              </p>
                            )}
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDetails;
