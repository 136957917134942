import React, { Component } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import NumberFormat from "react-number-format";
import Avatar from "../assets/img/default-user-avatar.png";

import Header from "../common/Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import moment from "moment";

var _home_id;

export class LawyersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"]
        ? JSON.parse(localStorage["appState"]).user.token
        : "",
      users: [],
      lawyers_total: "",
      currentPage: 1,
      usersPerPage: 20,
      query: "",
      isLoading: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  queryField = (e) => {
    e.preventDefault();
    this.setState({
      query: e.target.value,
    });
    axios
      .get(`${axios.defaults.baseURL}/search-lawyers/${this.state.query}`)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ users: json.data.users });
        } else alert("Fetching Users Failed!");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  };

  handleClick(event) {
    event.preventDefault();
    this.setState({
      currentPage: Number(event.target.id),
    });
  }

  componentDidMount() {
    this.fetchUsers();
    this.getChartData();
  }

  fetchUsers = (e) => {
    axios
      .get(`${axios.defaults.baseURL}/lawyers/list`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ users: json.data.users, isLoading: false });
        } else alert("Fetching Users Failed!");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  };

  getChartData() {
    axios
      .get(
        `${axios.defaults.baseURL}/chart/${
          JSON.parse(localStorage["appState"]).user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({
            lawyers_total: json.data.lawyers_total,
          });
          // } else alert("Cannot Fetch Data!");
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  }

  deactivateLawyer(id) {
    axios({
      method: "get",
      url: `${axios.defaults.baseURL}/go-offline/${id}`,
    }).then((response) => {
      //console.log(response);
      swal(
        "Success!",
        "Lawyer Deactivated, Will no longer get requests",
        "danger",
        {
          button: false,
          timer: 1500,
        }
      );
      return response;
    });
  }

  activateLawyer(id) {
    axios({
      method: "get",
      url: `${axios.defaults.baseURL}/go-online/${id}`,
    }).then((response) => {
      //console.log(response);
      swal(
        "Success!",
        "Lawyer activated, now active to get requests",
        "success",
        {
          button: false,
          timer: 1500,
        }
      );
      return response;
    });
  }

  render() {
    const { users, currentPage, usersPerPage, isLoading } = this.state;

    // Logic for displaying users
    const LawyersList = this.state.users;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = LawyersList.slice(indexOfFirstUser, indexOfLastUser);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <>
          {number == currentPage ? (
            <li
              className="pg-link active-link"
              key={number}
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </li>
          ) : (
            <li
              className="pg-link"
              key={number}
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </li>
          )}
        </>
      );
    });

    return (
      <div id="app" className="bg-gray-200">
        <div className="dashboard-page">
          <div className="private-layout">
            <div className="app-wrapper">
              <Sidebar />
              <Header />
              <div id="main-app" className="position-relative">
                <div className="container mt-4">
                  <div className="page-title">
                    <div className="row text-primary pt-4">
                      <div className="col-12">
                        <div
                          className="user-welcome"
                          style={{ overflow: "hidden" }}
                        >
                          <div className="wrapper d-flex">
                            <h4 className="d-inline-block">
                              All Lawyers ({this.state.lawyers_total})
                              <i className="fa fa-users"></i>
                            </h4>
                          </div>
                          <a
                            href="https://api.headfortfoundation.com/api/v1/download-data"
                            target="_blank"
                          >
                            Download Lawyers List
                          </a>
                          <div className="float-right">
                            <input
                              type="text"
                              name="query"
                              className="form-control"
                              placeholder=" Search lawyers by name or email..."
                              onChange={this.queryField}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-right col-2"></div>
                    </div>
                  </div>
                  <div className="row mt-4 align-items-stretch">
                    <table class="table">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Avatar</th>
                          {/* <th scope="col">ID</th> */}
                          <th scope="col">Firstname</th>
                          <th scope="col">Lastname</th>
                          <th scope="col">Email</th>
                          <th scope="col">Telephone</th>
                          <th scope="col">Join Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <tr className="blink_me">
                              <td>
                                <img
                                  src={Avatar}
                                  className="user-list-avatar"
                                  alt="user"
                                />
                              </td>
                              <td>Loading...</td>
                              {/* <td>Loading...</td> */}
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>
                                <Link
                                  to={{ pathname: `/admin/user-details/` }}
                                  className="btn mr-2 btn-primary"
                                >
                                  View Details
                                </Link>
                                {/* <Link to={{pathname: `/admin/user-plans/`}} className="btn mr-2 btn-warning">
                                  View Plans
                              </Link> */}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {currentUsers.map((user, index) => (
                              <tr>
                                <td>
                                  <img
                                    src={user.avatar || Avatar}
                                    className="user-list-avatar"
                                    alt="user"
                                  />
                                </td>
                                {/* <td>LNN/LAWYER/00{user.id}</td> */}
                                <td>{user.fname}</td>
                                <td>{user.lname}</td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                                <td>
                                  {moment(user.created_at.split(" ")[0]).format(
                                    "DD MMM, YYYY"
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/admin/lawyer-details/${user.id}`,
                                    }}
                                    className="btn mr-2 btn-primary"
                                  >
                                    View Details
                                  </Link>
                                  {user.astatus == "Online" ? (
                                    <Link
                                      onClick={this.deactivateLawyer.bind(
                                        this,
                                        user.id
                                      )}
                                      className="btn m-1 btn-danger"
                                    >
                                      Deactivate
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={this.activateLawyer.bind(
                                        this,
                                        user.id
                                      )}
                                      className="btn m-1 btn-success"
                                    >
                                      Activate
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                    {/* Product Listing */}
                    {/* {renderHomes} */}
                    {/* End Product Listing */}
                  </div>
                </div>
                <div className="text-center">{renderPageNumbers}</div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LawyersList;
