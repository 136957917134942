import React, { Component } from "react";
import {Link} from 'react-router-dom';

export class AdminMenuList extends Component {
  render() {

    let location = window.location.href.split('/')[window.location.href.split('/').length - 1];

    //console.log(location);

    const dashboardClass = location === "dashboard" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const usersClass = location === "users-list" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const lawyersClass = location === "lawyers-list" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const emergencyClass = location === "emergency-list" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const adviceClass = location === "advice-list" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const serviceClass = location === "service-list" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const volunteersClass = location === "volunteers-list" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const accountClass = location === "my-account" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";


    return (
      <div>
        <ul className="list-unstyled">
          <li>
            <Link
              to="/admin/dashboard"
              className={dashboardClass}
            >
              <i className="mr-1 fa fa-chart-line"></i> Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/admin/users-list"
              className={usersClass}
            >
              <i className="mr-1 fa fa-users"></i> Users Listing
            </Link>
          </li>
          <li>
            <Link
              to="/admin/lawyers-list"
              className={lawyersClass}
            >
              <i className="mr-1 fa fa-layer-group"></i> Lawyers Listing
            </Link>
          </li>
          <li>
            <Link
              to="/admin/emergency-list"
              className={emergencyClass}
            >
              <i className="mr-1 fa fa-lightbulb"></i> Emergency Cases
            </Link>
          </li>
          <li>
            <Link
              to="/admin/advice-list"
              className={adviceClass}
            >
              <i className="mr-1 fa fa-comments"></i> Legal Advice Cases
            </Link>
          </li>
          <li>
            <Link
              to="/admin/service-list"
              className={serviceClass}
            >
              <i className="mr-1 fa fa-gavel"></i> Professional Service Cases
            </Link>
          </li>
          <li>
            <Link
              to="/admin/volunteers-list"
              className={volunteersClass}
            >
              <i className="mr-1 fa fa-group"></i> Volunteers
            </Link>
          </li>
          <li>
            <Link
              to="/admin/my-account"
              className={accountClass}
            >
              <i className="mr-1 fa fa-user"></i> Admin Profile
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default AdminMenuList;
