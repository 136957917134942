import React from "react";
import { Link, Navigate, Route } from "react-router-dom";

import Logo from "../assets/img/logo-light.svg";
import Image from "../assets/img/login.png";

const AdminLogin = ({ history, loginAdmin = (f) => f }) => {
  let _email, _password;
  const handleAdminLogin = (e) => {
    e.preventDefault();
    loginAdmin(_email.value, _password.value);
  };

  const isLoggedIn = localStorage["appState"]
    ? JSON.parse(localStorage["appState"]).isLoggedIn
    : "";

  return isLoggedIn ? (
    <Navigate
      to={{ pathname: "/admin/dashboard", state: { from: "/admin/login" } }}
    />
  ) : (
    <main className="login-body d-flex align-items-center min-vh-100 py-3 py-md-0">
      <div className="container">
        <div className="card login-card">
          <div className="row no-gutters">
            <div className="col-md-7">
              <img src={Image} alt="login" className="login-card-img" />
            </div>
            <div className="col-md-5">
              <div className="card-body">
                <div className="brand-wrapper">
                  <img src={Logo} alt="logo" className="logo" />
                </div>
                <p className="login-card-description">Admin Login</p>
                <form
                  id="login-form"
                  action=""
                  onSubmit={handleAdminLogin}
                  method="post"
                >
                  <div className="form-group">
                    <label for="email" className="sr-only">
                      Email
                    </label>
                    <input
                      ref={(input) => (_email = input)}
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label for="password" className="sr-only">
                      Password
                    </label>
                    <input
                      ref={(input) => (_password = input)}
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="password"
                    />
                  </div>
                  <button
                    name="login"
                    id="email-login-btn"
                    className="btn btn-block login-btn mb-4"
                    type="submit"
                  >
                    Login
                  </button>
                </form>
                {/* <a href="#!" className="forgot-password-link">
                  Forgot password?
                </a>
                <p className="login-card-footer-text">
                  Don't have an account?{" "}
                  <Link to="/register" className="text-reset">
                    Register
                  </Link>
                </p> */}
                <nav className="login-card-footer-nav">
                  <Link to="/privacy">Privacy policy</Link>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default AdminLogin;
