import React, { Component } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";

import { Link } from "react-router-dom";

import NumberFormat from "react-number-format";

import axios from "axios";
import swal from "sweetalert";
import $ from "jquery";

export class LawyerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"]
        ? JSON.parse(localStorage["appState"]).user.token
        : "",
      user: [],
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    let id = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];

    axios
      .get(`${axios.defaults.baseURL}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ user: json.data.user, l_e_cases_total: json.data.l_e_cases_total, l_a_cases_total: json.data.l_a_cases_total, l_p_cases_total: json.data.l_p_cases_total });
          // } else alert("Cannot Fetch Data!");
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
    // alert(id)
  }

  makeAdmin(id) {

    axios({
        method: 'get',
        url: `${axios.defaults.baseURL}/make-admin/${id}`
        })
      .then((response) => {
        //console.log(response);
        return response;
      })
  };

  donePayment(id) {

    axios({
        method: 'get',
        url: `${axios.defaults.baseURL}/done-ref-payment/${id}`
        })
      .then((response) => {
        //console.log(response);
        return response;
      });
      this.getUser();
  };

  render() {
    return (
      <div id="app" className="bg-gray-200">
        <div className="dashboard-page">
          <div className="private-layout">
            <div className="app-wrapper">
              <Sidebar />
              <Header />
              <div id="main-app" className="position-relative">
                <div className="container mt-4">
                  <div className="page-title">
                    <div className="row text-primary py-2 pt-4">
                      <div className="col-10">
                        <div
                          className="user-welcome"
                          style={{ overflow: "hidden" }}
                        >
                          <Link to="/admin/lawyers-list">
                            <i className="ti-arrow-left"></i> Back to Lawyers List
                          </Link>
                          <div className="wrapper d-flex mt-2">
                            <h4 className="d-inline-block">
                              View Lawyer
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="text-right col-2"></div>
                    </div>
                  </div>

                  <div>
                    <div
                      data-v-22924209=""
                      class="dash-card-plan bg-white mb-4"
                    >
                      <div className="row">
                        <div className="col-md-4 text-center">
                            <img src={this.state.user.avatar} className="user-img p-2" alt="" />
                            <br/>
                            <br/>
                            {this.state.user.type == 1 ? (
                              <Link onClick={this.makeAdmin.bind(this, this.state.user.id)} className="btn mr-2 btn-warning">
                                  Make User Admin
                              </Link>
                            ) : null
                            }
                        </div>
                        <div className="col-md-8">
                          <div className="p-4">
                          <div className="">
                                  <h5 className="mb-0">Personal Details</h5>
                                </div>
                            <br />
                            <div className="row mb-4">
                              <div className="col">
                                <p className="mb-1 titlecase">
                                  <b>Firstname: {this.state.user.fname}</b>
                                </p>
                              </div>
                              <div className="col">
                                <p className="mb-1 titlecase">
                                    <b>Lastname: {this.state.user.lname}</b>
                                    </p>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col">
                                <p className="mb-1">
                                  <b>Email: {this.state.user.email}</b>
                                </p>
                              </div>
                              <div className="col">
                                <p className="mb-1 titlecase">
                                    <b>Telephone: {this.state.user.phone}</b>
                                    </p>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col">
                                <p className="mb-1">
                                  <b>Country: {this.state.user.country}</b>
                                </p>
                              </div>
                              <div className="col">
                                <p className="mb-1 titlecase">
                                    <b>State: {this.state.user.state}</b>
                                    </p>
                              </div>
                            </div>
                            <div className="row mb-4">
                            <div className="col">
                                <p className="mb-1 titlecase">
                                  <b>City: {this.state.user.city}</b>
                                </p>
                              </div>
                              <div className="col">
                                <p className="mb-1 titlecase">
                                  <b>Address: {this.state.user.address}</b>
                                </p>
                              </div>
                            </div>
                            <br/>
                            <div className="">
                                  <h5 className="mb-4">Cases Handled (LNN/LAWYER/00{this.state.user.id})</h5>
                                </div>
                                <div className="row mb-4">
                              <div className="col">
                                <p className="mb-1 titlecase">
                                  <b>Emergency: {this.state.l_e_cases_total || 0}</b>
                                </p>
                              </div>
                              <div className="col">
                                <p className="mb-1 titlecase">
                                  <b>Legal Advice: {this.state.l_a_cases_total || 0}</b>
                                </p>
                              </div>
                              <div className="col">
                                <p className="mb-1 titlecase">
                                  <b>Professional Service: {this.state.l_p_cases_total || 0}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
                </div>
                
        </div>
      </div>
    );
  }
}

export default LawyerDetails;
