import React, { Component } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import NumberFormat from "react-number-format";
import Avatar from "../assets/img/default-user-avatar.png";

import Header from "../common/Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

var _home_id;

export class ProfessionalServiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"]
        ? JSON.parse(localStorage["appState"]).user.token
        : "",
      users: [],
      users_total: "",
      currentPage: 1,
      usersPerPage: 20,
      query: "",
      isLoading: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  queryField = (e) => {
    e.preventDefault();
    this.setState({
      query: e.target.value,
    });
    axios
      .get(`${axios.defaults.baseURL}/search-users/${this.state.query}`)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ users: json.data.users });
        } else alert("Fetching Users Failed!");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  };

  handleClick(event) {
    event.preventDefault();
    this.setState({
      currentPage: Number(event.target.id),
    });
  }

  componentDidMount() {
    this.fetchUsers();
    this.getChartData();
  }

  fetchUsers = (e) => {
    axios
      .get(`${axios.defaults.baseURL}/service-all`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ users: json.data.service, isLoading: false });
        } else alert("Fetching Users Failed!");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  };

  getChartData() {
    axios
      .get(
        `${axios.defaults.baseURL}/chart/${
          JSON.parse(localStorage["appState"]).user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({
            users_total: json.data.users_total,
          });
          // } else alert("Cannot Fetch Data!");
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  }

  render() {
    const { users, currentPage, usersPerPage, isLoading } = this.state;

    // Logic for displaying users
    const ProfessionalServiceList = this.state.users;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = ProfessionalServiceList.slice(
      indexOfFirstUser,
      indexOfLastUser
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <>
          {number == currentPage ? (
            <li
              className="pg-link active-link"
              key={number}
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </li>
          ) : (
            <li
              className="pg-link"
              key={number}
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </li>
          )}
        </>
      );
    });

    return (
      <div id="app" className="bg-gray-200">
        <div className="dashboard-page">
          <div className="private-layout">
            <div className="app-wrapper">
              <Sidebar />
              <Header />
              <div id="main-app" className="position-relative">
                <div className="container mt-4">
                  <div className="page-title">
                    <div className="row text-primary pt-4">
                      <div className="col-12">
                        <div
                          className="user-welcome"
                          style={{ overflow: "hidden" }}
                        >
                          <div className="wrapper d-flex">
                            <h4 className="d-inline-block">
                              Professional Service Cases{" "}
                              <i className="fa fa-gavel"></i>
                            </h4>
                          </div>
                          {/* <div className="float-right">
                            <input
                            type="text"
                            name="query"
                            className="form-control"
                            placeholder=" Search users by name or email..."
                            onChange={this.queryField}
                            />
                            </div> */}
                        </div>
                      </div>
                      <div className="text-right col-2"></div>
                    </div>
                  </div>
                  <div className="row mt-4 align-items-stretch">
                    <table class="table">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Case ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Brief</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <tr className="blink_me">
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>Loading...</td>
                              <td>
                                <Link
                                  to={{ pathname: `/admin/user-details/` }}
                                  className="btn mr-2 btn-primary"
                                >
                                  View Details
                                </Link>
                                {/* <Link to={{pathname: `/admin/user-plans/`}} className="btn mr-2 btn-warning">
                                  View Plans
                              </Link> */}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {currentUsers.map((user, index) => (
                              <tr>
                                <td>{user.case_id}</td>
                                <td>
                                  {user.fname} {user.lname}
                                </td>
                                <td>{user.brief}</td>
                                <td>{user.date}</td>
                                <td>{user.status}</td>
                                <td>
                                  <Link
                                    to="/admin/case-details"
                                    state={{
                                      id: `${user.case_id}`,
                                      source: "service",
                                    }}
                                    className="btn mr-2 btn-primary"
                                  >
                                    View Details
                                  </Link>
                                  {/* <Link to={{pathname: `/admin/user-plans/${user.id}`}} className="btn mr-2 btn-warning">
                                  View Plans
                              </Link> */}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                    {/* Product Listing */}
                    {/* {renderHomes} */}
                    {/* End Product Listing */}
                  </div>
                </div>
                <div className="text-center">{renderPageNumbers}</div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfessionalServiceList;
