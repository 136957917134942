import React, { Component } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import { format } from "date-fns";

import { Link } from "react-router-dom";

import { NumericFormat } from "react-number-format";

import axios from "axios";
import swal from "sweetalert";
import $ from "jquery";

export class UserPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"]
        ? JSON.parse(localStorage["appState"]).user.token
        : "",
      user: [],
      my_homes: [],
      my_lands: [],
    };
  }

  componentDidMount() {
    this.getUser();
    this.getMyHomes();
    this.getMyLands();
  }

  getUser() {
    let id =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    axios
      .get(`${axios.defaults.baseURL}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ user: json.data.user[0] });
          // } else alert("Cannot Fetch Data!");
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
    // alert(id)
  }

  getMyHomes() {
    let id =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    axios
      .get(`${axios.defaults.baseURL}/my-homes/${id}`)
      .then((response) => {
        console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({
            my_homes: json.data.my_homes,
          });
        } else {
          //console.log(`Cannot fetch data!`);
        }
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  }

  getMyLands() {
    let id =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    axios
      .get(`${axios.defaults.baseURL}/my-lands/${id}`)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({
            my_lands: json.data.my_homes,
          });
        } else {
          //console.log(`Cannot fetch data!`);
        }
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  }

  updatePayment(id) {
    axios({
      method: "get",
      url: `${axios.defaults.baseURL}/home-update-payment/${id}`,
    }).then((response) => {
      //console.log(response);
      return response;
    });

    this.getMyHomes();
    this.getMyLands();
  }

  render() {
    return (
      <div id="app" className="bg-gray-200">
        <div className="dashboard-page">
          <div className="private-layout">
            <div className="app-wrapper">
              <Sidebar />
              <Header />
              <div id="main-app" className="position-relative">
                <div className="container mt-4">
                  <div className="page-title">
                    <div className="row text-primary py-2 pt-4">
                      <div className="col-10">
                        <div
                          className="user-welcome"
                          style={{ overflow: "hidden" }}
                        >
                          <Link to="/admin/users-list">
                            <i className="ti-arrow-left"></i> Back to Users List
                          </Link>
                          <div className="wrapper d-flex mt-2">
                            <h4 className="d-inline-block">
                              Active Plans for {this.state.user.fname}{" "}
                              {this.state.user.lname}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="text-right col-2"></div>
                    </div>
                  </div>
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Evest Plans</th>
                      </tr>
                    </thead>
                  </table>
                  {this.state.my_homes != "" ? (
                    <div>
                      {this.state.my_homes.map((my_homes, index) => (
                        <div
                          data-v-22924209=""
                          class="dash-card-plan bg-white mb-4"
                        >
                          <div className="row">
                            <div className="col-md-4 text-center">
                              <Link
                                to={{ pathname: `view-home/${my_homes.name}` }}
                              >
                                <img
                                  src={my_homes.image}
                                  className="plan-img p-2"
                                  alt=""
                                />
                              </Link>
                              {/* {my_homes.payments < my_homes.duration ? ( */}
                              <button
                                // onClick={this.makePayment.bind(this, my_lands.id)}
                                onClick={() =>
                                  axios
                                    .get(
                                      `${axios.defaults.baseURL}/generate-token`,
                                      {
                                        headers: {
                                          Authorization: `Bearer ${this.state.token}`,
                                        },
                                      }
                                    )
                                    .then(
                                      swal({
                                        text: "Please provide token sent to email to Add Payment!",
                                        content: "input",
                                        button: {
                                          text: "Submit Token",
                                          closeModal: false,
                                        },
                                      })
                                        .then((name) => {
                                          if (!name) throw null;

                                          axios
                                            .get(
                                              `${axios.defaults.baseURL}/authenticate-token/${name}`,
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${this.state.token}`,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              //console.log(response);
                                              return response;
                                            })
                                            .then((json) => {
                                              if (json.data.success) {
                                                this.setState({
                                                  isLoading: true,
                                                });
                                                axios({
                                                  method: "get",
                                                  url: `${axios.defaults.baseURL}/home-update-payment/${my_homes.id}`,
                                                })
                                                  .then((response) => {
                                                    //console.log(response);
                                                    return response;
                                                  })
                                                  .then((json) => {
                                                    if (json.data.success) {
                                                      this.getMyHomes();
                                                      this.getMyLands();
                                                      swal(
                                                        "Token Authenticated!",
                                                        "Payment added Successfully!",
                                                        "success",
                                                        {
                                                          button: false,
                                                          timer: 1500,
                                                        }
                                                      );
                                                    } else {
                                                      swal(
                                                        "Failed!",
                                                        "Error Adding Payment!",
                                                        "error",
                                                        {
                                                          button: false,
                                                          timer: 1500,
                                                        }
                                                      );
                                                    }
                                                  });
                                                // } else alert("Cannot Fetch Data!");
                                              } else
                                                swal(
                                                  "Oops!",
                                                  "Wrong Token Provided",
                                                  "error"
                                                );
                                            });
                                        })
                                        .catch((err) => {
                                          if (err) {
                                            swal(
                                              "Oh noes!",
                                              "The AJAX request failed!",
                                              "error"
                                            );
                                          } else {
                                            swal.stopLoading();
                                            swal.close();
                                          }
                                        })
                                    )
                                }
                                className="btn mr-2 btn-warning mb-2"
                              >
                                Make Payment (+1)
                              </button>
                              {/* )  :
                              <div className="blink_me">User has Completed Payments and Allocation should be done.</div>
                          } */}
                            </div>
                            <div className="col-md-8">
                              <div className="p-4">
                                <h5 className="text-primary mb-1 titlecase">
                                  {my_homes.name}
                                </h5>
                                <h6 className="text-primary mb-0 titlecase">
                                  <NumericFormat
                                    value={my_homes.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦"}
                                  />{" "}
                                  {my_homes.type}
                                </h6>
                                <br />
                                <div className="row mb-2">
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <b>Payments:</b> <br />
                                      <NumericFormat
                                        value={
                                          my_homes.payments * my_homes.price
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₦"}
                                      />{" "}
                                      ({my_homes.payments})
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <b>Duration:</b> <br />
                                      {my_homes.duration}{" "}
                                      {my_homes.type == "monthly"
                                        ? "Months"
                                        : my_homes.type == "weekly"
                                        ? "Weeks"
                                        : null}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <b>Total:</b> <br />
                                      <NumericFormat
                                        value={my_homes.total}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₦"}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <span style={{ color: "green" }}>
                                        LAST PAYMENT:
                                      </span>{" "}
                                      <br />
                                      {my_homes.payment_date}
                                      {/* {format(Date(my_homes.created_at), "MMMM Do, YYYY")} */}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <span style={{ color: "green" }}>
                                        START DATE:
                                      </span>{" "}
                                      <br />
                                      {my_homes.start_date}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <span style={{ color: "red" }}>
                                        END DATE:
                                      </span>{" "}
                                      <br />
                                      {my_homes.end_date}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      data-v-22924209=""
                      class="empty-placeholder dash-card bg-white"
                    >
                      {this.state.user.fname} {this.state.user.lname} has no
                      active evest plans yet.
                    </div>
                  )}
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Landvest Plans</th>
                      </tr>
                    </thead>
                  </table>
                  {this.state.my_lands != "" ? (
                    <div>
                      {this.state.my_lands.map((my_lands, index) => (
                        <div
                          data-v-22924209=""
                          class="dash-card-plan bg-white mb-4"
                        >
                          <div className="row">
                            <div className="col-md-4 text-center">
                              <Link
                                to={{ pathname: `view-home/${my_lands.id}` }}
                              >
                                <img
                                  src={my_lands.image}
                                  className="plan-img p-2"
                                  alt=""
                                />
                              </Link>
                              {/* {my_lands.payments < my_lands.duration ? ( */}
                              <button
                                // onClick={this.makePayment.bind(this, my_lands.id)}
                                onClick={() =>
                                  axios
                                    .get(
                                      `${axios.defaults.baseURL}/generate-token`,
                                      {
                                        headers: {
                                          Authorization: `Bearer ${this.state.token}`,
                                        },
                                      }
                                    )
                                    .then(
                                      swal({
                                        text: "Please provide token sent to email to Add Payment!",
                                        content: "input",
                                        button: {
                                          text: "Submit Token",
                                          closeModal: false,
                                        },
                                      })
                                        .then((name) => {
                                          if (!name) throw null;

                                          axios
                                            .get(
                                              `${axios.defaults.baseURL}/authenticate-token/${name}`,
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${this.state.token}`,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              //console.log(response);
                                              return response;
                                            })
                                            .then((json) => {
                                              if (json.data.success) {
                                                this.setState({
                                                  isLoading: true,
                                                });
                                                axios({
                                                  method: "get",
                                                  url: `${axios.defaults.baseURL}/home-update-payment/${my_lands.id}`,
                                                })
                                                  .then((response) => {
                                                    //console.log(response);
                                                    return response;
                                                  })
                                                  .then((json) => {
                                                    if (json.data.success) {
                                                      this.getMyHomes();
                                                      this.getMyLands();
                                                      swal(
                                                        "Token Authenticated!",
                                                        "Payment added Successfully!",
                                                        "success",
                                                        {
                                                          button: false,
                                                          timer: 1500,
                                                        }
                                                      );
                                                    } else {
                                                      swal(
                                                        "Failed!",
                                                        "Error Adding Payment!",
                                                        "error",
                                                        {
                                                          button: false,
                                                          timer: 1500,
                                                        }
                                                      );
                                                    }
                                                  });
                                                // } else alert("Cannot Fetch Data!");
                                              } else
                                                swal(
                                                  "Oops!",
                                                  "Wrong Token Provided",
                                                  "error"
                                                );
                                            });
                                        })
                                        .catch((err) => {
                                          if (err) {
                                            swal(
                                              "Oh noes!",
                                              "The AJAX request failed!",
                                              "error"
                                            );
                                          } else {
                                            swal.stopLoading();
                                            swal.close();
                                          }
                                        })
                                    )
                                }
                                className="btn mr-2 btn-warning mb-2"
                              >
                                Make Payment (+1)
                              </button>
                              {/* )  :
                              <div className="blink_me">User has Completed Payments and Allocation should be done.</div>
                          } */}
                            </div>
                            <div className="col-md-6">
                              <div className="p-4">
                                <h5 className="text-primary mb-1 titlecase">
                                  {my_lands.name}
                                </h5>
                                <h6 className="text-primary mb-0 titlecase">
                                  <NumericFormat
                                    value={my_lands.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦"}
                                  />{" "}
                                  {my_lands.type}
                                </h6>
                                <br />
                                <div className="row mb-2">
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <b>Payments:</b> <br />
                                      <NumericFormat
                                        value={
                                          my_lands.payments * my_lands.price
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₦"}
                                      />{" "}
                                      ({my_lands.payments})
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <b>Duration:</b> <br />
                                      {my_lands.duration}{" "}
                                      {my_lands.type == "monthly"
                                        ? "Months"
                                        : my_lands.type == "weekly"
                                        ? "Weeks"
                                        : null}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <b>Total:</b> <br />
                                      <NumericFormat
                                        value={my_lands.total}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₦"}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <span style={{ color: "green" }}>
                                        LAST PAYMENT:
                                      </span>{" "}
                                      <br />
                                      {my_lands.payment_date}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <span style={{ color: "green" }}>
                                        START DATE:
                                      </span>{" "}
                                      <br />
                                      {my_lands.start_date}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="mb-1 titlecase">
                                      <span style={{ color: "red" }}>
                                        EXPECTED END DATE:
                                      </span>{" "}
                                      <br />
                                      {my_lands.end_date}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      data-v-22924209=""
                      class="empty-placeholder dash-card bg-white"
                    >
                      {this.state.user.fname} {this.state.user.lname} has no
                      active landvest plans yet.
                    </div>
                  )}
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserPlans;
