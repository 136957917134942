import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";

import Privacy from "./Privacy";
import Terms from "./Terms";

// Admin Pages
import AdminLogin from "./admin/AdminLogin";
import AdminDashboard from "./admin/Dashboard";
import LawyersList from "./admin/Lawyers";
import UsersList from "./admin/Users";
import UserPlans from "./admin/UserPlans";
import UserDetails from "./admin/UserDetails";
import AdminProfile from "./admin/Profile";
import LawyerDetails from "./admin/LawyerDetails";
import VolunteersList from "./admin/Volunteers";
import VolunteerDetails from "./admin/VolunteerDetails";
import EmergencyList from "./admin/EmergencyList";
import AdviceList from "./admin/AdviceList";
import ProfessionalServiceList from "./admin/ProfessionalServiceList";
import CaseDetails from "./admin/CaseDetails";

// Define App URL
axios.defaults.baseURL = "https://api.headfortfoundation.com/api/v1";
// axios.defaults.baseURL = 'http://localhost:8000/api/v1';

function PrivateRoute({ children }) {
  const isLoggedIn = localStorage["appState"]
    ? JSON.parse(localStorage["appState"]).isLoggedIn
    : "";

  return isLoggedIn ? <>{children}</> : <Navigate to="/" />;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      user: {},
    };
  }
  _loginAdmin = (email, password) => {
    $("#login-form button")
      .attr("disabled", "disabled")
      .html(
        '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
      );
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios
      .post(`${axios.defaults.baseURL}/admin-login`, formData)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          // alert(json.data.message);
          swal("Success!", json.data.message, "success", {
            button: false,
            timer: 1500,
          });
          const { id, fname, lname, email, phone } = json.data.user;
          const { token } = json.data.token;

          let userData = {
            id,
            fname,
            lname,
            email,
            phone,
            token,
            timestamp: new Date().toString(),
          };
          let appState = {
            isLoggedIn: true,
            user: userData,
          };
          // save app state with user date in local storage
          localStorage["appState"] = JSON.stringify(appState);
          this.setState({
            isLoggedIn: appState.isLoggedIn,
            user: appState.user,
            token: appState.token,
          });
          // redirect home
          this.props.history.push("/admin/dashboard");
        } else swal("Error!", json.data.message, "error");
        $("#login-form button").removeAttr("disabled").html("Login");
      })
      .catch((error) => {
        // alert(`An Error Occured! ${error}`);
        swal("Login Failed!", "" + error.response.data.message, "error", {
          button: false,
          timer: 1500,
        });
        $("#login-form button").removeAttr("disabled").html("Login");
      });
  };

  _loginUser = (email, password) => {
    $("#login-form button")
      .attr("disabled", "disabled")
      .html(
        '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
      );
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios
      .post(`${axios.defaults.baseURL}/login`, formData)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          // alert(json.data.message);
          swal("Success!", json.data.message, "success", {
            button: false,
            timer: 1500,
          });
          const { id, fname, lname, email, phone } = json.data.user;
          const { token } = json.data.token;

          let userData = {
            id,
            fname,
            lname,
            email,
            phone,
            token,
            timestamp: new Date().toString(),
          };
          let appState = {
            isLoggedIn: true,
            user: userData,
          };
          // save app state with user date in local storage
          localStorage["appState"] = JSON.stringify(appState);
          this.setState({
            isLoggedIn: appState.isLoggedIn,
            user: appState.user,
            token: appState.token,
          });
          // redirect home
          this.props.history.push("/dashboard");
        } else swal("Error!", json.data.message, "error");
        $("#login-form button").removeAttr("disabled").html("Login");
      })
      .catch((error) => {
        // alert(`An Error Occured! ${error}`);
        swal("Login Failed!", "" + error.response.data.message, "error", {
          button: false,
          timer: 1500,
        });
        $("#login-form button").removeAttr("disabled").html("Login");
      });
  };

  _registerUser = (fname, lname, remail, phone, rpassword, ref_url) => {
    $("#reg button")
      .attr("disabled", "disabled")
      .html(
        '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
      );

    var formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", remail);
    formData.append("phone", phone);
    formData.append("password", rpassword);
    formData.append("ref_url", ref_url);

    axios
      .post(`${axios.defaults.baseURL}/register`, formData)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          // alert(`Registration Successful!`);
          swal("Success!", json.data.message, "success", {
            button: false,
            timer: 1500,
          });
          const { id, fname, lname, email, phone } = json.data.user;
          const { token } = json.data.token;
          let userData = {
            id,
            fname,
            lname,
            email,
            phone,
            token,
            timestamp: new Date().toString(),
          };
          let appState = {
            isLoggedIn: true,
            user: userData,
          };
          // save app state with user date in local storage
          localStorage["appState"] = JSON.stringify(appState);
          this.setState({
            isLoggedIn: appState.isLoggedIn,
            user: appState.user,
            token: appState.token,
          });
          // redirect home
          this.props.history.push("/dashboard");
        } else {
          // alert(`Registration Failed!`);
          swal("Failed", "Registration Failed", "error");
          $("#reg button").removeAttr("disabled").html("Register");
        }
      })
      .catch((error) => {
        // alert("An Error Occured!" + error);
        let res =
          (error.response.data.message.fname || "") +
          (error.response.data.message.lname || "") +
          (error.response.data.message.email || "") +
          (error.response.data.message.phone || "") +
          (error.response.data.message.password || "");
        swal("Error!", "" + res, "error", {
          button: false,
          // timer: 1500
        });
        //console.log(error);
        $("#reg button").removeAttr("disabled").html("Register");
      });
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route index element={<AdminLogin loginAdmin={this._loginAdmin} />} />
          {/* <Route path="/privacy" element={<Privacy />} /> */}
          {/* <Route path="/terms" element={<Terms />} /> */}
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/my-account"
            element={
              <PrivateRoute>
                <AdminProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/users-list"
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/user-details/:id"
            element={
              <PrivateRoute>
                <UserDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/user-plans"
            element={
              <PrivateRoute>
                <UserPlans />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/lawyers-list"
            element={
              <PrivateRoute>
                <LawyersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/lawyer-details/:id"
            element={
              <PrivateRoute>
                <LawyerDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/volunteers-list"
            element={
              <PrivateRoute>
                <VolunteersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/volunteer-details/:id"
            element={
              <PrivateRoute>
                <VolunteerDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/emergency-list"
            element={
              <PrivateRoute>
                <EmergencyList />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/advice-list"
            element={
              <PrivateRoute>
                <AdviceList />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/service-list"
            element={
              <PrivateRoute>
                <ProfessionalServiceList />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/case-details"
            element={
              <PrivateRoute>
                <CaseDetails />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
