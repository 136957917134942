import React, { Component } from "react";
// import Page from "react-page-loading";

import Header from "../common/Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

import NumberFormat from "react-number-format";

import { Line, Bar, Chart, Doughnut } from "react-chartjs-2";

import axios from "axios";
import swal from "sweetalert";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"]
        ? JSON.parse(localStorage["appState"]).user.token
        : "",
      user: [],
      homes_total: "",
      users_total: "",
      lands_total: "",
      homes_sub_total: "",
      lands_sub_total: "",
      homes_sum_total: "",
      lands_sum_total: "",
    };
  }
  componentDidMount() {
    axios
      .get(
        `${axios.defaults.baseURL}/user/${
          JSON.parse(localStorage["appState"]).user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({ user: json.data.user });
          // } else alert("Cannot Fetch Data!");
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
    // alert(id)
    this.getChartData();
  }

  getChartData() {
    axios
      .get(
        `${axios.defaults.baseURL}/chart/${
          JSON.parse(localStorage["appState"]).user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response;
      })
      .then((json) => {
        if (json.data.success) {
          this.setState({
            users_total: json.data.users_total,
            lawyers_total: json.data.lawyers_total,
            e_cases_total: json.data.e_cases_total,
            o_e_cases_total: json.data.o_e_cases_total,
            c_e_cases_total: json.data.c_e_cases_total,
            a_cases_total: json.data.a_cases_total,
            o_a_cases_total: json.data.o_a_cases_total,
            c_a_cases_total: json.data.c_a_cases_total,
            p_cases_total: json.data.p_cases_total,
            o_p_cases_total: json.data.o_p_cases_total,
            c_p_cases_total: json.data.c_p_cases_total,
          });
          // } else alert("Cannot Fetch Data!");
        } else swal("Oops!", "Cannot Fetch Data!", "error");
      })
      .catch((error) => {
        console.error(`An Error Occured! ${error}`);
      });
  }

  logoutUser = () => {
    localStorage.clear();
    window.location.href = "/auth";
  };
  render() {
    const doudata = {
      labels: ["Red", "Green", "Yellow"],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };
    const linedata = {
      labels: ["Evest", "Landvest"],
      datasets: [
        {
          label: "Plans Chart",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [this.state.homes_total, this.state.lands_total, 0],
        },
      ],
    };
    return (
      <>
        <div id="app" className="bg-gray-200">
          <div className="dashboard-page">
            <div className="private-layout">
              <div className="app-wrapper">
                <Sidebar />
                <Header />
                <div id="main-app" className="position-relative">
                  <div className="container mt-4">
                    <div className="row">
                      <div class="mb-2 col-md-6 col-12">
                        <Link
                          to="/admin/users-list"
                          class="btn dash-card p-4 shadow-xl outline action-btn w-100"
                          target="_self"
                          style={{ backgroundColor: "#FB1DA4" }}
                        >
                          <h3
                            style={{
                              marginTop: "100px",
                              fontStyle: "italic",
                              textTransform: "capitalize",
                            }}
                            className="text-white"
                          >
                            All Users: {this.state.users_total || 0}
                          </h3>
                        </Link>
                      </div>
                      <div class="mb-2 col-md-6 col-12">
                        <Link
                          to="/admin/lawyers-list"
                          class="btn dash-card p-4 shadow-xl outline action-btn w-100"
                          target="_self"
                          style={{ backgroundColor: "#673ab7" }}
                        >
                          <h3
                            style={{
                              marginTop: "100px",
                              fontStyle: "italic",
                              textTransform: "capitalize",
                            }}
                            className="text-white"
                          >
                            Lawyers: {this.state.lawyers_total || 0}
                          </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="container mt-4">
                    <div className="row">
                      <div class="mb-2 col-md-4 col-12">
                        <Link
                          to="/admin/emergency-list"
                          class="btn dash-card p-4 shadow-xl outline action-btn w-100"
                          target="_self"
                          style={{ backgroundColor: "#F93C61" }}
                        >
                          <h3
                            style={{
                              marginTop: "50px",
                              fontStyle: "italic",
                              textTransform: "capitalize",
                            }}
                            className="text-white"
                          >
                            Emergency: {this.state.e_cases_total || 0}
                          </h3>
                          <br />
                          <p>Open: {this.state.o_e_cases_total || 0}</p>
                          <p>Closed: {this.state.c_e_cases_total || 0}</p>
                        </Link>
                      </div>
                      <div class="mb-2 col-md-4 col-12">
                        <Link
                          to="/admin/advice-list"
                          class="btn dash-card p-4 shadow-xl outline action-btn w-100"
                          target="_self"
                          style={{ backgroundColor: "#feaa00" }}
                        >
                          <h3
                            style={{
                              marginTop: "50px",
                              fontStyle: "italic",
                              textTransform: "capitalize",
                            }}
                            className="text-white"
                          >
                            Legal Advice: {this.state.a_cases_total || 0}
                          </h3>
                          <br />
                          <p>Open: {this.state.o_a_cases_total || 0}</p>
                          <p>Closed: {this.state.c_a_cases_total || 0}</p>
                        </Link>
                      </div>
                      <div class="mb-2 col-md-4 col-12">
                        <Link
                          to="/admin/service-list"
                          class="btn dash-card p-4 shadow-xl outline action-btn w-100"
                          target="_self"
                          style={{ backgroundColor: "#1090c2" }}
                        >
                          <h3
                            style={{
                              marginTop: "50px",
                              fontStyle: "italic",
                              textTransform: "capitalize",
                            }}
                            className="text-white"
                          >
                            Professional: {this.state.p_cases_total || 0}
                          </h3>
                          <br />
                          <p>Open: {this.state.o_p_cases_total || 0}</p>
                          <p>Closed: {this.state.c_p_cases_total || 0}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminDashboard;
