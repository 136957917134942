import React, { Component } from "react";

import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import Header from "../common/Header";
import Sidebar from "./Sidebar";
import Avatar from "../assets/img/default-user-avatar.png";

var _fname,
  _lname,
  _email,
  _phone,
  _country,
  _state,
  _city,
  _address,
  _password;

export class AdminProfile extends Component {
  constructor(props) {
    super(props);
    // let { user } = this.props.appstate;
    this.state = {
      token: localStorage["appState"]
        ? JSON.parse(localStorage["appState"]).user.token
        : "",
      user: [],
      imageSource: "",
      file: null,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // //console.log(id);
    this.getUser();
  }

  getUser(){
    axios
    .get(
      `${axios.defaults.baseURL}/user/${
        JSON.parse(localStorage["appState"]).user.id
      }`,
      {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      }
    )
    .then((response) => {
      //console.log(response);
      return response;
    })
    .then((json) => {
      if (json.data.success) {
        this.setState({ user: json.data.user });
        // } else alert("Cannot Fetch Data!");
      } else swal("Oops!", "Cannot Fetch Data!", "error");
    })
    .catch((error) => {
      console.error(`An Error Occured! ${error}`);
    });
  }

  handleUpdate = (e) => {
    e.preventDefault();

    $("#update-profile")
      .attr("disabled", "disabled")
      .html(
        '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
      );

    var formData = new FormData();
    formData.append("fname", _fname.value);
    formData.append("lname", _lname.value);
    formData.append("email", _email.value);
    formData.append("phone", _phone.value);
    formData.append("avatar", this.state.file);
    formData.append("country", _country.value);
    formData.append("state", _state.value);
    formData.append("city", _city.value);
    formData.append("address", _address.value);
    formData.append("password", _password.value);

    //console.log(formData);

    axios({
      method: "post",
      url: `${axios.defaults.baseURL}/user/${
        JSON.parse(localStorage["appState"]).user.id
      }`,
      data: formData,
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        //console.log(response);
        return response;
      })
      .then((json) => {
        const {
          id,
          _fname,
  _lname,
  _email,
  _phone,
  _country,
  _state,
  _city,
  _address,
  _password,
        } = json.data.user;
        const { token } = json.data.token;
        let userData = {
          id,
          _fname,
  _lname,
  _email,
  _phone,
  _country,
  _state,
  _city,
  _address,
  _password,
          token,
          timestamp: new Date().toString(),
        };
        let appState = {
          isLoggedIn: true,
          user: userData,
        };
        // save app state with user date in local storage
        localStorage["appState"] = JSON.stringify(appState);
        this.setState({
          isLoggedIn: appState.isLoggedIn,
          user: appState.user,
          token: appState.token,
        });

        if (json.data.success === true) {
          swal("Updated!", "" + json.data.message, "success", {
            button: false,
            timer: 1500,
          });
          $("#update-profile").removeAttr("disabled").html("Update Profile");
          // redirect home
          // this.props.history.push("/my-account");
        } else {
          // alert(json.data.message);
          swal("Alert!", "" + json.data.message, "warning");
          $("#update-profile").removeAttr("disabled").html("Update Profile");
        }
      })
      .catch((error) => {
        // alert("An Error Occured!" + error);
        //console.log(error.response.data);
        if (error.response.data.message.fname === "") {
          swal("Warning!", "" + error.response.data.message.fname, "warning");
        } else {
          swal("Warning!", "Please fill all fields", "warning");
        }
        $("#update-profile").removeAttr("disabled").html("Update Profile");
      });
  };

  // onChange(e) {
  //   this.setState({file:e.target.files[0]});
  // }

  uploadPhoto = (e) => {
    this.photoUpload.click();
  };

  onChange = (event) => {
    this.setState({
      imageSource: "",
      imageData: "",
      file: event.target.files[0],
    });
    const file = event.target.files[0];
    //console.log(file);

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.setState({
          imageSource: event.target.result,
          imageData: file,
        });
      };
    }
  };

  render() {
    $("#fname-input").attr("value", this.state.user.fname);
    $("#lname-input").attr("value", this.state.user.lname);
    $("#email-input").attr("value", this.state.user.email);
    $("#phone-input").attr("value", this.state.user.phone);
    $("#country-input").attr("value", this.state.user.country);
    $("#state-input").attr("value", this.state.user.state);
    $("#city-input").attr("value", this.state.user.city);
    $("#address-input").attr("value", this.state.user.address);
    $("#accounttype-input").attr("value", this.state.user.type == 1 ?("Administrator Account") : "User Account");

    return (
      <div id="app" className="bg-gray-200">
        <div className="dashboard-page">
          <div className="private-layout">
            <div className="app-wrapper">
              <Sidebar />
              <Header />
              <div id="main-app" className="position-relative">
                <div className="container mt-4">
                  <div data-v-4c22b934="">
                    <div data-v-4c22b934="" id="profile-page">
                      <div className="page-title">
                        <div className="row text-primary py-2 pt-4">
                          <div className="col-10">
                            <div
                              className="user-welcome"
                              style={{ overflow: "hidden" }}
                            >
                              <div className="wrapper d-flex">
                                <h4 className="d-inline-block">Admin Profile</h4>
                              </div>
                            </div>
                          </div>
                          <div className="text-right col-2"></div>
                        </div>
                      </div>
                      <div className="pb-5">
                        <div className="row">
                          <div className="col-12">
                            <div className="wrapper dash-card bg-white">
                              <div className="p-4 text-center">
                                <img
                                  src={
                                    this.state.imageSource
                                      ? this.state.imageSource :
                                      this.state.user.avatar ?
                                      this.state.user.avatar
                                      : Avatar
                                  }
                                  id="user-logo"
                                  className="shadow-xl user-avatar"
                                />
                                <p
                                  role="button"
                                  onClick={this.uploadPhoto}
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Change Photo
                                </p>
                              </div>
                              <hr className="mt-0"></hr>
                              <div className="px-4">
                                <h5 className="mb-0">Personal Data</h5>
                              </div>
                              <form
                                id="reg"
                                action=""
                                onSubmit={this.handleUpdate}
                                method="post"
                              >
                                <input
                                  ref={(input) => (this.photoUpload = input)}
                                  onChange={this.onChange}
                                  autoComplete="off"
                                  id="image-input"
                                  name="image"
                                  type="file"
                                  hidden
                                />
                                <div className="p-4">
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        First Name
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_fname = input)}
                                          id="fname-input"
                                          name="fname"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Last Name
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_lname = input)}
                                          id="lname-input"
                                          name="lname"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Email
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_email = input)}
                                          id="email-input"
                                          name="email"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Telphone
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_phone = input)}
                                          id="phone-input"
                                          name="phone"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Country
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_country = input)}
                                          id="country-input"
                                          name="country"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        State
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_state = input)}
                                          id="state-input"
                                          name="state"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        City
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_city = input)}
                                          id="city-input"
                                          name="city"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Address
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_address = input)}
                                          id="address-input"
                                          name="address"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>

                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Password
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_password = input)}
                                          id="password-input"
                                          name="password"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                          placeholder="Enter New Password (leave blank to maintain old password)"
                                        />
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <div className="form-row">
                                      <legend
                                        tabindex="-1"
                                        className="col-sm-4 col-lg-3 bv-no-focus-ring col-form-label"
                                      >
                                        Account Type
                                      </legend>
                                      <div
                                        tabindex="-1"
                                        role="group"
                                        className="bv-no-focus-ring col"
                                      >
                                        <input
                                          ref={(input) => (_password = input)}
                                          id="accounttype-input"
                                          name="accounttype"
                                          type="text"
                                          
                                          aria-required="true"
                                          className="form-control"
                                        disabled/>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                                <hr className="mt-0"></hr>
                                <div className="p-4">
                                  <div className="text-center">
                                    <button
                                      id="update-profile"
                                      type="submit"
                                      className="btn btn-primary my-4"
                                    >
                                      Update Profile
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminProfile;
