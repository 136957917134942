import React, { Component } from "react";
import {Link} from 'react-router-dom';

export class MenuList extends Component {
  render() {

    let location = window.location.href.split('/')[window.location.href.split('/').length - 1];

    //console.log(location);

    const dashboardClass = location === "dashboard" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";
    
    const evestClass = location === "evest" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";
    

    const landvestClass = location === "landvest" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";


    const plansClass = location === "my-plans" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const tranxClass = location === "my-transactions" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";
    
    const refClass = location === "referrals" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";

    const accountClass = location === "my-account" ? "btn w-100 text-left check-hover py-3 bg-transparent router-link-exact-active router-link-active btn-secondary" : "btn w-100 text-left check-hover py-3 bg-transparent btn-secondary";


    return (
      <div>
        <ul className="list-unstyled">
          <li>
            <Link
              to="/dashboard"
              className={dashboardClass}
            >
              <i className="mr-1 ti-dashboard"></i> Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/evest"
              className={evestClass}
            >
              <i className="mr-1 ti-home"></i> EVest
              <span className="badge badge-primary ml-2">Hot🔥</span>
            </Link>
          </li>
          <li>
            <Link
              to="/landvest"
              className={landvestClass}
            >
              <i className="mr-1 ti-map"></i> LandVest
              <span className="badge badge-primary ml-2"> Hot🔥</span>
            </Link>
          </li>
          <li>
            <Link
              to="/my-plans"
              className={plansClass}
            >
              <i className="mr-1 ti-credit-card"></i> My Plans
            </Link>
          </li>
          <li>
            <Link
              to="/my-transactions"
              className={tranxClass}
            >
              <i className="mr-1 ti-list"></i> My Transactions
            </Link>
          </li>
          <li>
            <Link
              to="/referrals"
              className={refClass}
            >
              <i className="mr-1 ti-world"></i> Referrals
            </Link>
          </li>
          <li>
            <Link
              to="/my-account"
              className={accountClass}
            >
              <i className="mr-1 ti-user"></i> My Account
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default MenuList;
